<template>
  <!-- 签署流程模板 -->
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="warning" @click="toCreate">发起签署</el-button>
      </el-form-item>
      <el-form-item label="签约方">
        <el-input v-model="form.sign_name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input
          v-model="form.contacts_phone"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #createTime="{ row }">
        <div>发起时间：{{ row.create_time }}</div>
        <div v-if="row.sign_time ">签约时间：{{ row.sign_time }}</div>
      </template>
      <template #status="{ row }">
        <div>
          {{ statusMap[row.status] }}
        </div>
      </template>
      <template #sign_name="{ row }">
         
        <div>
            <el-tag size="small" v-if="row.sign_target == 1" type="success">企业</el-tag>
        <el-tag size="small" v-if="row.sign_target == 2" type="primary">个人</el-tag>
          {{ row.sign_name }}
        </div>
      </template>
      <template #contacts_phone="{ row }">
        <div>
          {{ row.contacts }}
        </div>
        <div>
          {{ row.contacts_phone }}
        </div>
      </template>

      <template #handler="{ row }">
        <div>
          <!-- <el-button size="small" type="primary" @click="toOrgSign(row)"
            >签署</el-button
          > -->
          <el-button
            size="small"
            type="primary"
            v-if="row.sign_flow_id"
            @click="getUserUrl(row)"
            >签署链接</el-button
          >
          <el-button
            size="small"
            type="success"
            v-if="row.sign_flow_id"
            @click="getfileUrl(row)"
            >查看合同</el-button
          >
        </div>
      </template>
    </auto-table>

    <el-dialog title="用户签署链接" :visible.sync="urlShow" width="30%">
      <div>
        <el-form>
          <el-form-item label=""> 可将合同签署链接分享给签署方 </el-form-item>
          <el-form-item label="">
            <el-input placeholder="请输入内容" disabled v-model="signUrl">
              <template slot="append">
                <div class="point" id="copy-url" @click="copyUrl()">
                  复制链接
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog title="合同文件，点击查看" :visible.sync="docShow" width="30%">
      <div>
        <el-link
          v-for="(doc, i) in docs"
          :key="i"
          :href="doc.downloadUrl || doc.fileDownloadUrl"
          target="_blank"
          >{{ doc.fileName }}</el-link
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        sign_name: "",
        contacts_phone: "",
      },
      DataList: [],
      Option: [
        { name: "任务名称", value: "title" },
        { name: "签约方", value: "sign_name", type: "custom" },
        { name: "签约人", value: "contacts_phone", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "操作时间", value: "createTime", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",

      statusMap: {
        0: "草稿",
        1: "签署中",
        2: "完成",
        3: "撤销",
        5: "过期",
        7: "拒签",
      },
      timeList: [],
      urlShow: false,
      signUrl: "",
      docShow: false,
      docs: [],
    };
  },

  mounted() {
    this.getList(1);
  },
  methods: {
    toCreate() {
      this.$router.push({
        name: "createSign",
      });
    },
    toOrgSign(row) {
      let data = {
        signFlowId: row.signFlowId,
        psnId: "93795f2ad2244f449d6168151e18be35",
        orgId: "2c4ed4c5690c476d9ebfde1efa034ca1",
      };

      this.$post("user/esign/signUrl", data).then((res) => {});
    },
    getfileUrl(row) {
      if (row.status == 2) {
        this.$get("user/esign/fileDownloadUrl", { id: row.sign_flow_id }).then(
          (res) => {
            this.docs = res.data.data.files;
            this.docShow = true;
          }
        );
      } else {
        this.$get("user/esign/querySignFlowDetails", {
          id: row.sign_flow_id,
        }).then((res) => {
          this.$get("user/esign/previewFileDownloadUrl", {
            signFlowId: row.sign_flow_id,
            fileId: res.data.data.docs[0].fileId,
          }).then((v) => {
            this.docs = [v.data.data];
            this.docShow = true;
          });
        });
      }
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.signUrl);
      this.$message("复制成功");
    },
    //获取签约链接
    getUserUrl(row) {
      let data = {
        signFlowId: row.sign_flow_id,
      };

      this.$post("user/esign/signUrl", data).then((res) => {
        this.signUrl = res.data.data.shortUrl;
        this.urlShow = true;
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/signFlowRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          sign_name: this.form.sign_name || null,
          contacts_phone: this.form.contacts_phone || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>